<template>
  <v-container fluid>
    <Header @create-custom-field="onCreateTemplates" />
    <Placeholder @action='onCreateTemplates' :btnText="$t('templatesPage.createTemplate')" :title="$t('templatesPage.emptyTitle')" v-if="!hasTemplates"/>
    <v-card class="py-6 px-6 mt-3" rounded="lg" elevation="0" width="100%" v-else>
      <v-row justify="space-between" class="align-center">
        <v-col cols="12" md="auto" class="d-flex align-center">
          <SearchComponent :search="filter.name" @update:search="filter.name = $event" :placeholder="$t('placeHolder.searchByName')" />

          <FilterDialog :data="filter" :data-sources="dataSources" @update-filter-condition="updateFilterCondition" />
        </v-col>

        <v-col cols="12" md="auto">
          <v-row justify="end" class="align-center">
            <SettingsMenu :filterItems="filteredMenuHeaders" />
          </v-row>
        </v-col>
      </v-row>

      <TemplateTable :headers="filteredHeaders" :items="templates" :total="totalTemplates" itemKey="uid" @edit="onEditTemplate" @update-pagination="onUpdatePagination" @delete="onDeleteTemplate" />
    </v-card>

    <CreateUpdateDialog v-model="showCreateUpdateDialog" :data="selectedTemplates" @create-template="createTemplate" @update-template="updateTemplate" @close-dialog="showCreateUpdateDialog = false" />

    <DeleteConfirmDialog v-model="showDeleteConfirmDialog" :template="selectedTemplates" @delete="deleteTemplate" />
  </v-container>
</template>

<script>
import _ from 'lodash';
import makeTemplateService from '@/services/api/template';
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import Header from '@/components/Admin/CustomTemplates/Header';
import FilterDialog from '@/components/Admin/CustomField/FilterDialog.vue';
import SearchComponent from '@/components/Project/SearchComponent.vue';
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import TemplateTable from '@/components/Admin/CustomTemplates/TemplateTable.vue';
import CreateUpdateDialog from '@/components/Admin/CustomTemplates/CreateUpdateDialog.vue';
import DeleteConfirmDialog from '@/components/Admin/CustomTemplates/DeleteConfirmDialog.vue';
import Placeholder from '@/components/base/Placeholder.vue';
import { handleNetworkStatusError } from '@/mixins/redirect';

export default {
  name: 'Templates',
  mixins: [handleNetworkStatusError],
  components: {
    Header,
    FilterDialog,
    SearchComponent,
    SettingsMenu,
    TemplateTable,
    CreateUpdateDialog,
    DeleteConfirmDialog,
    Placeholder,
  },

  data()
  {
    return {
      projectKey: this.$route.params.key,
      handle: this.$route.params.handle,
      templateService: makeTemplateService(this.$api),
      filter: {
        name: '',
        types: [],
        sources: [],
        startDate: null,
        endDate: null,
      },
      headers: [
        {
          text: this.$t('name'),
          align: 'start',
          sortable: true,
          value: 'name',
          class: 'elevation-0 rounded-l-lg',
          checked: true
        },
        {
          text: this.$t('creationDate'),
          value: 'createdAt',
          sortable: true,
          checked: true
        },
        {
          text: this.$t('templatesPage.creator'),
          value: 'createdBy',
          sortable: true,
          checked: true
        },
        {
          text: '',
          value: 'uid',
          sortable: false,
          checked: true,
          class: 'rounded-r-lg',
        },
      ],
      originalCustomFields: [],
      filteredCustomFields: [],
      showCreateUpdateDialog: false,
      showDeleteConfirmDialog: false,
      selectedTemplates: {
        uid: '',
        name: '',
        type: '',
        source: '',
        options: [],
      },
      customFieldService: null,
      isLoading: false,
      errorMessage: '',
      dataSources: [],
      templates: [],
      totalTemplates: 0,
    };
  },

  computed: {
    filteredHeaders()
    {
      return this.headers.filter((header) =>
      {
        const filterItem = this.filteredMenuHeaders.find((item) => item.value === header.value);
        return filterItem ? filterItem.checked : true;
      });
    },
    filteredMenuHeaders()
    {
      return this.headers.filter((header) => header.text !== 'Actions')
    },
    hasTemplates() {
      return this.totalTemplates > 0;
    },
  },

  watch: {
    'filter.name': {
      handler: _.debounce(function ()
      {
        this.initTemplates();
      }, 500),
    },
  },
  async mounted() {
    await this.initTemplates();
  },

  methods: {
    async getCustomFields()
    {
      this.isLoading = true;
      try {
        const response = await this.customFieldService.getCustomFields(this.handle);
        this.originalCustomFields = response.data;

        this.dataSources = _.uniq(_.map(this.originalCustomFields, 'source'));
      } catch (err) {
        this.isLoading = true;
        this.errorMessage = err.message;
      }

      this.filterCustomFields();
    },
    async initTemplates(paginationOptions) {
      const searchParams = new URLSearchParams();
      searchParams.set('current_page', paginationOptions ? paginationOptions.page : 1);
      searchParams.set('per_page', paginationOptions ? paginationOptions.itemsPerPage : 10);

      if (this.filter.name) {
        searchParams.set('name', this.filter.name);
      }

      try {
        const response = await this.templateService.getTemplates(this.$route.params.handle, this.projectKey, searchParams.toString());
        this.templates = response.data.templates;
        this.totalTemplates = response.data.total;
      } catch (error) {
        // Use the mixin's method to handle the error
        this.redirectOnError(error.response?.status);
        showErrorToast(this.$swal, 'fetchError', { item: 'templates' });
      }
    },

    onCreateTemplates()
    {
      this.selectedTemplates = {
        uid: '',
        name: '',
        type: '',
        source: '',
        options: [],
      };

      this.showCreateUpdateDialog = true;
    },

    async createTemplate(customField)
    {
      try {

        const response = await this.templateService.createTemplate(this.handle, this.projectKey, customField);
        this.showCreateUpdateDialog = false;
        showSuccessToast(this.$swal, 'createSuccess', { item: 'templates' });
        this.initTemplates();
      } catch (err) {
        showErrorToast(this.$swal, 'createError', { item: 'templates' });
        this.isLoading = true;
        this.errorMessage = err.message;
      }

      this.filterCustomFields();
    },

    onEditTemplate(template)
    {
      this.selectedTemplates = {
        uid: template.uid || '',
        name: template.name || '',
        dataType: template.dataType || '',
        customFields: template.customFields || [],
      };

      this.showCreateUpdateDialog = true;
    },

    async updateTemplate(template)
    {

      try {
        const response = await this.templateService.updateTemplate(this.handle, this.projectKey, template.uid, template);
        this.initTemplates();
        this.showCreateUpdateDialog = false;
        showSuccessToast(this.$swal, 'updateSuccess', { item: 'templates' });
      } catch (err) {
        showErrorToast(this.$swal, 'updateError', { item: 'templates' });
        this.isLoading = true;
        this.errorMessage = err.message;
      }

      this.filterCustomFields();
    },

    onDeleteTemplate(template)
    {
      this.selectedTemplates = {
        uid: template.uid || '',
        name: template.name || '',
      };

      this.showDeleteConfirmDialog = true;
    },

    async deleteTemplate()
    {
      this.showDeleteConfirmDialog = false;

      try {

        const response = await this.templateService.deleteTemplate(this.handle, this.projectKey, this.selectedTemplates.uid);
        this.initTemplates();
        showSuccessToast(this.$swal, 'deleteSuccess', { item: 'templates' });
      } catch (err) {
        showErrorToast(this.$swal, 'deleteError', { item: 'templates' });
        this.isLoading = true;
        this.errorMessage = err.message;
      }
    },
    onUpdatePagination(options)
    {
      this.initTemplates(options);
    },

    updateFilterCondition(data)
    {
      this.filter = {
        ...this.filter,
        types: data.types,
        sources: data.sources,
        startDate: data.startDate,
        endDate: data.endDate,
      };

      this.filterCustomFields();
    },

    filterCustomFields()
    {
      let filteredCustomFields = _.cloneDeep(this.originalCustomFields);

      if (this.filter.name) {
        filteredCustomFields = filteredCustomFields.filter((item) => item.name.includes(this.filter.name));
      }

      if (this.filter.types.length > 0) {
        filteredCustomFields = filteredCustomFields.filter((item) => this.filter.types.includes(item.type));
      }

      if (this.filter.sources.length > 0) {
        filteredCustomFields = filteredCustomFields.filter((item) => this.filter.sources.includes(item.source));
      }

      if (this.filter.startDate) {
        filteredCustomFields = filteredCustomFields.filter((item) => item.createdAt >= this.filter.startDate);
      }

      if (this.filter.endDate) {
        filteredCustomFields = filteredCustomFields.filter((item) => item.createdAt <= this.filter.endDate);
      }

      this.filteredCustomFields = filteredCustomFields;
    },
  },
};
</script>
