export const handleNetworkStatusError = {
    methods: {
        async redirectOnError(statusCode) {
            if (statusCode === 404 || statusCode === 403) {
                this.$router.push({ name: '404' });
            }
            if (statusCode === 424) {
                this.$router.push({ name: 'Setup' });
            }
        }
    }
}
